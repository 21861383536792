<template>
  <b-container class="mt-4">
    <b-row>
      <b-col cols="11">
        <h4>SFTP Group Details</h4>
      </b-col>
      <b-col>
        <b-button
          :disabled="loading"
          variant="primary"
          :to="`/sftp/groups/${server}/${name}/edit`"
          class="float-right">
          <feather type="edit"></feather>
          Edit
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="loading">
      <b-col cols="12">
        <b-spinner></b-spinner>
      </b-col>
    </b-row>
    <b-row v-else class="mt-3">
      <b-col cols="12">
        <b-card>
          <p class="card-text"><b>Name:</b> {{ group.name }}</p>
          <p class="card-text"><b>Permissions</b></p>
          <div class="ml-2">
            <b-row v-for="(permission, idx) in permissions" :key="idx" class="mt-1 d-flex align-items-center">
              <b-col cols="3">
                <b-form-group
                  :id="`label-permission-path-${idx}`"
                  label="Folder Path"
                  :label-for="`permission-path-${idx}`">
                  <b-form-input
                    :id="`permission-path-${idx}`"
                    v-model="permission.path"
                    type="text"
                    disabled
                    placeholder="Enter filepath"
                    required/>
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-form-group
                  :id="`label-permission-role-${idx}`"
                  label="Role"
                  :label-for="`permission-role-${idx}`">
                  <b-form-select
                    :id="`permission-role-${idx}`"
                    disabled
                    v-model="permission.role"
                    :options="roles"/>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SFTPGroupDetails',
  data() {
    return {
      loading: false,
      server: null,
      name: null,
      group: null,
      permissions: [],
      roles: [
        { value: 'admin', text: 'Admin' },
        { value: 'write', text: 'Write' },
        { value: 'read', text: 'Read' },
        { value: 'deny', text: 'Deny' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      canAccessSftpUsersAndGroups: 'User/canAccessSftpUsersAndGroups',
    }),
  },
  async beforeMount() {
    if (!this.canAccessSftpUsersAndGroups) {
      this.$router.push('/not-found');
      return;
    }

    this.server = this.$route.params.server;
    this.name = this.$route.params.name;

    await this.getGroup();
  },
  methods: {
    async getGroup() {
      try {
        this.loading = true;

        const { data } = await this.$store.dispatch('Sftp/Groups/getGroup', {
          server: this.server,
          name: this.name,
        });

        this.group = data;
        this.permissions = Object.entries(data.user_settings.permissions).map(([ path, role ]) => ({ path, role }));
      } catch (error) {
        console.error(error);
        this.$noty.error(error.message);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
